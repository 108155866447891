.charger-location-line {
  width: inherit;
  border: 2px solid #f2f2f2;
  margin-bottom: 50px;
}

.delete-charger-btn {
  display: flex;
  height: 40px;
  width: 125px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 10px;
  background-color: rgba(231, 233, 237, 0.5);
}
.delete-charger-btn-active {
  background: #ed1f0e;
  border: none !important;
}
.delete-charger-btn .delete-text {
  color: #111f47;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.delete-charger-btn .delete-text-active {
  color: #e7e9ed;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.delete-charger-popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  text-align: left;
  height: 100%;
}

.underline {
  text-decoration: underline;
}
