@import "../../styles.css";

.roaming,
.roaming * {
  box-sizing: border-box;
}

.roaming {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.roaming-span {
  color: var(--main-font-color);
  font-size: 24px;
  font-weight: 600;
}
.charger-modal-container {
  z-index: 1001;
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 42%;
  background-color: var(--main-background);
  overflow: auto;
}
.roaming-span2 {
  color: var(--clickable-button-background);
  font-size: 24px;
  font-weight: 600;
}

.register-btn {
  width: auto;
  height: auto;
  min-width: 155px;
  min-height: 40px;
  border-radius: 10px;
  background-color: var(--non-clickable-button-background);
  padding: 0px 8px;
  color: var(--non-clickable-button-font-color);
}

.register-btn-active {
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
}

.market-dropdown-roaming-container {
  position: absolute;
  top: 50px;
  width: 230px;
  background: var(--main-background);
  border-radius: 5px;
  box-shadow: var(
    --calendareffect-box-shadow,
    0px 14px 29.6px 0px rgba(0, 0, 0, 0.05),
    0px 30px 50px 10px rgba(0, 0, 0, 0.06)
  );

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 5px;
  padding: 8px;
  z-index: 20003;
  margin-bottom: 10px;
}

.market-dropdown-roaming-container::-webkit-scrollbar {
  width: 4px;
  border-radius: 12px;
}

.market-dropdown-roaming-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.22);
  border-radius: 4px;
}

.market-dropdown-roaming-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.roaming-page-btn {
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  width: 160px;
  color: #111f47;
  background-color: rgba(231, 233, 237, 0.5);
  transition: transform 0.4s ease, background 0.4s ease, color 0.4s ease;
}

.roaming-left-curved {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.roaming-right-curved {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.roaming-page-btn-active {
  color: #e7e9ed;
  background: #111f47;
}

.available-roaming-green {
  color: #0ab051;
  background-color: #e6f5ec;
}
.in-use-roaming-blue {
  color: #1e4cdc;
  background-color: #eff2fc;
}
.offline-roaming-red {
  color: #ff3139;
  background-color: #ffd9db;
}

#green-text {
  color: #0ab051;
}

#orange-text {
  color: #ff8800;
}

#red-text {
  color: #ff3139;
}
