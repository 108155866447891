@import "../../../styles.css";

.resetButtons {
  border-radius: 10px;
  border-style: solid;
  border-color: var(--non-clickable-button-border-color);
  border-width: 1px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  min-width: 100px;

  color: var(--non-clickable-button-font-color);
  background-color: var(--non-clickable-button-background);
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.resetButtonsActive {
  border: 1px solid var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.chargerLogButton {
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  background-color: var(--non-clickable-button-background);
  border-color: var(--non-clickable-button-border-color);
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;

  color: var(--non-clickable-button-font-color);
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.chargerLogButtonActive {
  background: var(--blue, #1e4cdc);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.vertical-line {
  height: 30px;
  width: 2px;
  border-radius: 10px;
  background-color: #b8bcc85c;
}

.operations-remote-management,
.operations-remote-management * {
  box-sizing: border-box;
}

.operations-remote-management {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.operations-remote-management-span {
  color: var(--main-font-color);
  font-size: 24px;
  font-weight: 600;
}

.operations-remote-management-span2 {
  color: var(--clickable-button-background);
  font-size: 24px;
  font-weight: 600;
}

.historyOutlineButton {
  border-radius: 10px;
  border-style: solid;
  border-color: var(--non-clickable-button-border-color);
  background-color: var(--non-clickable-button-background);
  border-width: 1px;
  padding: 8px 8px 8px 8px;
  height: 40px;
  width: 190px;
  color: var(--non-clickable-button-font-color);
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}

.subtable-row {
  background-color: var(--main-background) !important;
}

.subtable-tr {
  background-color: var(--subtable-row-background);
}

.subtable-td {
  background-color: var(--subtable-row-background);
  color: var(--subtable-font-color);
  border-bottom: 2px solid var(--table-row-border-color);
  text-align: left;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
}

#subtable-td {
  border-top: 0px;
  border-bottom: 0px;
}

.connector-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 400;
  text-align: center;
  height: 27px;
  width: 120px;
}

.available {
  color: #0ab051;
  background-color: #e6f5ec;
}
.occupied {
  background: rgba(255, 168, 0, 0.2);
  color: #ffa800;
}

.unavailable {
  color: #1e4cdc;
  background-color: #eff2fc;
}

.offline {
  color: #ed1f0e;
  background-color: #fde9e7;
}

.remote-popup-main-text {
  color: var(--main-font-color);
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

.popout-other-text {
  color: var(--main-font-color);
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}

.message-container {
  background-color: var(--clickable-button-background);
  border-radius: 0px 0px 10px 10px;
  color: var(--clickable-button-font-color);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: fixed;
  top: 0%;
  left: 35%;
  width: 404px;
  height: 43px;
}

.subtable-header {
  background-color: var(--subtable-header-background);
  color: var(--subtable-header-font-color);
  text-align: left;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  border-bottom: 2px solid var(--table-header-border-color);
  padding: 11px;
}

.operations-remote-btns {
  display: flex;
  justify-content: space-between;
  margin: 30px 20px 10px 20px;
}

.info-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #111f47;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-button .i {
  color: #111f47;
  font-size: 18px;
  font-weight: 600;
}
.info-button:hover {
  border-color: #1e4cdc;
}

.info-button:hover .i {
  color: #1e4cdc;
}

#faulted-connectors-col {
  color: #be190b;
  background-color: #fde9e7;
}
