@import "../../../styles.css";

.network-onboarding,
.network-onboarding * {
  box-sizing: border-box;
}

.network-onboarding {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.network-onboarding-span {
  color: var(--main-font-color);
  font-size: 24px;
  font-weight: 600;
}

.network-onboarding-span2 {
  color: var(--clickable-button-background);
  font-size: 24px;
  font-weight: 600;
}
.finish-onboarding-btn {
  border-radius: 10px;
  border: 1px solid var(--non-clickable-button-border-color);
  background-color: var(--non-clickable-button-background);
  padding: 10px 20px;
  font-size: 14px;
  color: var(--non-clickable-button-font-color);
  line-height: 20px;
  font-weight: 500;
  height: 40px;
}

.finish-onboarding-btn-active {
  border: 1px solid var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
}

.add-new-charger-btn {
  border-radius: 10px;
  border: 1px solid var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  padding: 10px 20px;
  font-size: 14px;
  color: var(--clickable-button-font-color);
  line-height: 20px;
  font-weight: 500;
  height: 40px;
}

.onboarding-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e7e9ed;
  border-radius: 10px;
}

.onboarding-btn {
  background: #e7e9ed;
  color: #111f47;
  padding: 10px;
  gap: 6px;
  width: 205px;
  height: 43px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.4s ease, background 0.4s ease, color 0.4s ease;
}

.onboarding-btn-active {
  background: #0d2263;
  color: #e9edfc;
}

.onboarding-btn-num {
  background: #0d2263;
  color: #e7e9ed;
  border-radius: 10px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.onboarding-btn-num-active {
  background: #1e4cdc;
}

.onboarding-num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.onboarding-gray-line {
  height: 2px;
  background-color: #f2f2f2;
}
