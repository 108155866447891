.language-container {
  width: auto;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  cursor: pointer;
  background: #e7e9ed80;
}

.language-container p {
  font-size: 16px;
  font-weight: 500;
}

.dropdown-menu {
  width: auto;
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 2;
  background-color: white;
  padding: 0px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.dropdown-menu button {
  background: none;
  font-size: 14px;
  font-weight: 500;
  border: none;
  text-align: left;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;
}
.dropdown-menu button:hover {
  background: #eeeeee;
}
.language-container .rotate-img {
  transition: transform 0.2s ease;
}

.language-container.open .rotate-img {
  transform: rotate(180deg);
}
